<template>
  <board-list-widget :org-ref="orgRef" title="leads" entity-type="lead" board-link="leadBoard" read-only />
</template>

<script>
import { BoardListWidget } from '@argon/evo/boards/widgets'
import { NAMESPACE as ORG_NAMESPACE } from '@argon/iam/organizations/store'
import { mapActions } from 'vuex'

export default {
  name: 'LeadBoardList',
  components: {
    BoardListWidget
  }
}
</script>
